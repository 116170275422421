<template>
	<div class="app-container" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">绩效考核确认</div>
		</div>
		<section class="sections-container">
			<el-row type="flex" align="middle">
				<el-avatar
					:size="80"
					fit="cover"
					:src="
						staffDetail.avatarUrl
							? staffDetail.avatarUrl
							: 'https://ehr-prod.oss-cn-shenzhen.aliyuncs.com/template/878b8e2202ca46bdbc479c7ebe1cd460.png'
					"
				>
					<div slot="error" class="image-slot">
						<i class="el-icon-picture-outline"></i>
					</div>
				</el-avatar>
				<div class="sections-container-m">
					<h2>
						{{ staffDetail.staffName }}({{ staffDetail.jobNumber }})<el-tag
							:type="_returnStatusColor(staffDetail.status)"
							style="margin: 10px; transform: translateY(-3px)"
							size="small"
							>{{ staffDetail.statusName }}</el-tag
						>
					</h2>
					<p>部门：{{ staffDetail.orgName }} 职位：{{ staffDetail.officeName }}</p>
				</div>
			</el-row>
		</section>
		<section class="sections-container" style="padding-bottom: 5px; margin-bottom: 0">
			<el-form :model="searchForm" inline ref="form" class="searchForm" label-width="100px" size="small">
				<el-form-item label="绩效考核名称">
					<el-select v-model="searchForm.performanceId" clearable filterable placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key"> </el-option>
					</el-select>
				</el-form-item>
				<el-button type="primary" @click="_getList(1)" size="small" style="margin: 1px 0 0 5px" icon="el-icon-search">查询</el-button>
			</el-form>
		</section>
		<div style="background-color: #fff; padding-bottom: 40px">
			<el-table :data="list" stripe :header-cell-style="tableHeaderColor" :cell-style="tableCellStyle" style="margin-bottom: 39.5px">
				<el-table-column
					:fixed="i == 0 ? true : false"
					v-for="(col, i) in columns"
					:prop="col.prop"
					:key="i"
					:label="col.label"
					align="center"
					:width="i == 0 || i == 7 ? '150px' : i == 6 ? '280px' : ''"
					:show-overflow-tooltip="i == 7 || i == 8 ? false : true"
				>
					<template v-slot="{ row }" v-if="col.prop == 'status'">
						<el-link :underline="false" style="cursor: auto" :type="_returnStatusColor(row.status)">{{ row.statusName }}</el-link>
					</template>
					<template v-slot="{ row }" v-else-if="col.prop == 'contentName'">
						<el-link
							v-if="row.contentFileUrlList"
							type="primary"
							:href="`http://view.officeapps.live.com/op/view.aspx?src=${row.contentFileUrlList[0].url}`"
							target="_blank"
							>{{ row.contentFileUrlList[0].name }}</el-link
						>
					</template>
					<template v-slot="{ row }" v-else-if="col.prop == 'performanceName'">
						<div class="performanceName">{{ row.performanceName }}</div>
					</template>
					<template v-slot="{ row }" v-else-if="col.prop == 'confirmNote'">
						<div class="confirmNote">{{ row.confirmNote }}</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" align="center" label="操作" width="130px">
					<template v-slot="{ row }">
						<el-button v-if="row.status == 8 && staffIdStatus != null && row.startStatus != 2" type="text" @click="_handleRowClick(row)"
							>确认</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="searchForm.totalNum > 0"
				@size-change="_handleSizeChange"
				@current-change="_handleCurrentChange"
				:current-page="searchForm.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="searchForm.pageSize"
				style="text-align: right"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchForm.totalNum"
			>
			</el-pagination>
		</div>
		<el-dialog :visible.sync="dialogVisible" class="loser" width="680px" center>
			<span slot="title" class="dialog-title">绩效考核复合确认</span>
			<div class="losetitle">
				<div>
					<strong
						>{{ staffDetail.staffName }}({{ staffDetail.jobNumber }})
						<span>{{ staffDetail.orgName }} {{ staffDetail.officeName }}</span></strong
					>
				</div>
				<el-row :gutter="20">
					<el-col :span="12"> 考核周期：{{ currentForm.assessStartTime }}至{{ currentForm.assessEndTime }} </el-col>
					<el-col :span="12"> 审批人：{{ staffDetail.repObjName }} </el-col>
				</el-row>
			</div>
			<p class="border-line"></p>
			<el-form ref="form" :rules="currentForm.status == 1 ? rules : rules2" :model="currentForm" label-width="80px" size="mini">
				<el-form-item label="绩效等级">
					<span>{{ currentForm.performanceScore }}</span>
				</el-form-item>
				<el-form-item label="评价说明">
					<div>{{ currentForm.confirmNotes }}</div>
				</el-form-item>
				<el-form-item prop="status" label="审批状态">
					<el-radio-group v-model="currentForm.status" @change="$refs['form'].clearValidate()">
						<el-radio label="1">公布</el-radio>
						<el-radio label="0">不通过</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item prop="confirmNote" label="审批说明">
					<el-input
						type="textarea"
						v-model="currentForm.confirmNote"
						:autosize="{ minRows: 4, maxRows: 4 }"
						maxlength="999"
						show-word-limit
						placeholder="请输入审批说明"
					/>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="medium">取消</el-button>
				<el-button type="primary" @click="_handlecheckForm" size="medium">保存</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible2" width="530px" :show-close="false" top="28vh" class="submig_dia" center>
			<div class="submig_dia_title" slot="title" v-if="this.currentForm.performanceName.length > 11">
				<div>{{ this.currentForm.performanceName.substr(0, 11) }}</div>
				<div>{{ this.currentForm.performanceName.substr(12, this.currentForm.performanceName.length - 1) }}</div>
			</div>
			<div class="submig_dia_title" slot="title" v-else>
				<div></div>
				<div>{{ this.currentForm.performanceName }}确认</div>
			</div>
			<p class="submit_text">您确认要公布{{ staffDetail.staffName }}绩效等级</p>
			<p class="submit_text">
				绩效 <span>{{ currentForm.performanceScore }}</span>
			</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible2 = false" size="medium">取消</el-button>
				<el-button type="primary" size="medium" @click="_submit">公布</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { performanceAssessAffirmDetail, performanceAssessStaffRecord, performanceNameChoice, performanceAssessAffirmSubmit } from '@/api/performance';
export default {
	data() {
		return {
			id: this.$route.query.id,
			searchForm: {
				staffId: this.$route.query.staffid,
				performanceId: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			staffDetail: {},
			columns: [
				{ label: '绩效考核名称', prop: 'performanceName' },
				{ label: '考核计划周期', prop: 'assessCycle' },
				{ label: '状态', prop: 'status' },
				{ label: '绩效', prop: 'performanceScore' },
				{ label: '审批人', prop: 'upStaffName' },
				{ label: '评定人', prop: 'tutorshipStaffNmae' },
				{ label: '评价说明', prop: 'confirmNote' },
				{ label: '附件', prop: 'contentName' },
				{ label: '操作人', prop: 'confirmName' }
			],
			rules: {
				status: [{ required: true, message: '请选择状态', trigger: 'change' }]
			},
			rules2: {
				status: [{ required: true, message: '请选择状态', trigger: 'change' }],
				confirmNote: [{ required: true, message: '请输入审批内容', trigger: 'blur' }]
			},
			list: [],
			currentForm: { performanceName: '' },
			currentIndex: '',
			options: [],
			dialogVisible: false,
			dialogVisible2: false,
			loading: false,
			staffIdStatus: this.$store.state.app.staffId
		};
	},
	mounted() {
		this._getDetail();
		performanceNameChoice({}).then((res) => {
			this.options = res.list;
		});
	},
	methods: {
		_getValue(eve) {
			this.searchForm.department = eve ? eve.id : '';
		},
		_getDetail() {
			performanceAssessAffirmDetail({ id: this.id }).then((res) => {
				this.staffDetail = res;
			});
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			performanceAssessStaffRecord(this.searchForm).then((res) => {
				this.list = res.list;
				this.searchForm.totalNum = res.totalNum;
			});
		},
		_submit() {
			const _params = {
				id: this.currentForm.id,
				status: this.currentForm.status,
				confirmNote: this.currentForm.confirmNote
			};

			this.loading = true;
			performanceAssessAffirmSubmit(_params).then((res) => {
				if (res.code == 0) {
					if (_params.status == 0) {
						this.dialogVisible = false;
						this.currentForm.statusName = '确认失败';
						this.currentForm.status = 9;
					} else {
						this.dialogVisible = false;
						this.dialogVisible2 = false;
						this.currentForm.statusName = '已确认';
						this.currentForm.status = 10;
					}
					this.currentForm.confirmNote = this.currentForm.confirmNotes;
					this.$set(this.list, this.currentIndex, this.currentForm);
				}
				this.loading = false;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_returnStatusColor(status) {
			switch (Number(status)) {
				case 9:
					return 'danger';
				case 10:
					return 'primary';
				default:
					return 'success';
			}
		},
		_handleRowClick(row) {
			this.currentForm = {};
			this.currentForm = { ...row };
			this.currentForm.confirmNotes = this.currentForm.confirmNote;
			this.currentForm.confirmNote = '';
			this.currentForm.status = '1';
			this.currentIndex = this.list.findIndex((value) => value == row);
			this.dialogVisible = true;
		},
		_handlecheckForm() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if (this.currentForm.status == 0) {
						this._submit();
					} else {
						this.dialogVisible2 = true;
					}
				} else {
					return false;
				}
			});
		},
		_cellClick(row) {
			if (row.confirmNote) {
				this._open(row.confirmNote);
			}
		},
		_open(text) {
			text ? this.$alert(text, '评价说明') : '';
		},
		tableHeaderColor({ rowIndex }) {
			if (rowIndex === 0) {
				return 'background-color: #F0F8FF;';
			}
		},
		tableCellStyle({ columnIndex }) {
			if (columnIndex === 7) {
				return 'cursor : pointer;';
			}
		}
	}
};
</script>

<style lang="scss">
.sections-container {
	overflow: hidden;
	margin-bottom: 20px;
	border-radius: 4px;
	padding: 20px 40px;
	background-color: #fff;
	.sections-container-m {
		display: inline-block;
		margin-left: 21px;
		h2 {
			font-family: PingFangSC-Medium;
			font-size: 24px;
			color: #2b3642;
		}
		p {
			font-family: PingFangSC-Regular;
			font-size: 16px;
			color: #777e8c;
		}
	}
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 30px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}
.performanceName {
	width: 130px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}
.confirmNote {
	max-height: 90px;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
.loser {
	// .el-dialog__header{
	//   border-bottom: 1px solid #EAEDF1;
	// }
	.dialog-title {
		font-family: PingFangSC-Medium;
		font-size: 21px;
		// color: #409EFF;
		letter-spacing: 0;
		line-height: 24px;
	}
	.losetitle {
		margin: 0 20px;
		padding-bottom: 20px;
		// border-bottom: 1px dashed #ccc;
		strong {
			font-family: PingFangSC-Medium;
			font-size: 16px;
			color: #2b3642;
			span {
				font-family: PingFangSC-Regular;
				font-size: 14px;
				color: #777e8c;
				margin-left: 9px;
			}
		}
		div {
			margin-top: 10px;
			line-height: 20px;
		}
	}
	.border-line {
		width: 93%;
		margin: 0 20px 20px;
		height: 1px;
		background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
		background-size: 10px 1px;
		background-repeat: repeat-x;
	}
	.loseType,
	.reason {
		margin-left: 20px;
		.el-radio-group {
			margin: 20px 0;
		}
	}
	.reason {
		.el-radio {
			min-width: 16%;
			height: 25px;
		}
	}
	.buttonDiv {
		text-align: center;
		margin-top: 20px;
	}
}
.submig_dia {
	.el-dialog__header {
		padding: 0;
	}
	.submig_dia_title {
		height: 150px;
		background: url('/static/images/evaluate.png') no-repeat center top;
		background-size: 100% auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		div {
			font-family: HYk2gj;
			font-size: 36px;
			font-weight: bold;
			color: #ffffff;
			letter-spacing: 6px;
			line-height: 22px;
			margin: 10px 20px;
		}
	}
	.submit_text {
		font-family: HYk2gj;
		font-size: 24px;
		color: #392377;
		letter-spacing: 0;
		text-align: center;
		line-height: 50px;
		span {
			color: #ff5d4e;
			font-size: 30px;
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
