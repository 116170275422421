import { post, get } from "./api";
/**
 * 绩效名称筛选
 * @param params
 * @returns {*}
 */
export const performanceNameChoice = (params) => post('/v1/performance/name/choice', { request: params });
/**
 * 绩效确认人列表
 * @param params
 * @returns {*}
 */
export const userAdminStaffList = (params) => post('/v1/user/admin/staff/list', { request: params });
/**
 * 查询绩效等级下拉
 * @param params
 * @returns {*}
 */
export const performanceScoreList = (params) => post('/v1/performance/score/list', { request: params });
/**
 * 绩效考核列表
 * @param params
 * @returns {*}
 */
export const performanceAssessList = (params) => post('/v1/performance/assess/list', { request: params });
/**
 * 绩效考核状态数量统计
 * @param params
 * @returns {*}
 */
export const performanceNumberStatistics = (params) => post('/v1/performance/number/statistics', { request: params });
/**
 * 绩效考核开关
 * @param params
 * @returns {*}
 */
export const performanceAssessSwitch = (params) => post('/v1/performance/assess/switch', { request: params });
/**
 * 绩效考核删除
 * @param params
 * @returns {*}
 */
export const performanceDelete = (params) => post('/v1/performance/delete', { request: params });
/**
 * 绩效考核员工列表
 * @param params
 * @returns {*}
 */
export const performanceAssessStaffList = (params) => post('/v1/performance/assess/staff/list', { request: params });
/**
 * 绩效新增
 * @param params
 * @returns {*}
 */
export const performanceAdd = (params) => post('/v1/performance/add', { request: params });
/**
 * 绩效考核详情
 * @param params
 * @returns {*}
 */
export const performanceDetail = (params) => post('/v1/performance/detail', { request: params });
/**
 * 绩效修改
 * @param params
 * @returns {*}
 */
export const performanceUpdate = (params) => post('/v1/performance/update', { request: params });
/**
 * 绩效等级列表
 * @param params
 * @returns {*}
 */
export const performanceLevelList = (params) => post('/v1/performance/level/list', { request: params });
/**
 * 绩效等级下载模板
 * @param params
 * @returns {*}
 */
export const performanceLevelDownload = (id) => get('/v1/performance/level/download/template?performanceId=' + id);
/**
 * 绩效等级新增
 * @param params
 * @returns {*}
 */
export const performanceLevelAdd = (params) => post('/v1/performance/level/add', { request: params });
/**
 * 绩效等级修改
 * @param params
 * @returns {*}
 */
export const performanceLevelUpdate = (params) => post('/v1/performance/level/update', { request: params });
/**
 * 绩效考核确认列表
 * @param params
 * @returns {*}
 */
export const performanceAssessAffirmList = (params) => post('/v1/performance/assess/affirm/list', { request: params });
/**
 * 绩效考核确认详情
 * @param params
 * @returns {*}
 */
export const performanceAssessAffirmDetail = (params) => post('/v1/performance/assess/affirm/detail', { request: params });
/**
 * 绩效考核员工绩效记录
 * @param params
 * @returns {*}
 */
export const performanceAssessStaffRecord = (params) => post('/v1/performance/assess/staff/record', { request: params });
/**
 * 绩效考核确认提交
 * @param params
 * @returns {*}
 */
export const performanceAssessAffirmSubmit = (params) => post('/v1/performance/assess/affirm/submit', { request: params });
/**
 * 绩效考核模板列表
 * @param params
 * @returns {*}
 */
export const performanceTemplateList = (params) => post('/v1/performance/template/list', { request: params });
/**
 * 考核表模板列表
 * @param params
 * @returns {*}
 */
export const performanceTableTemplateList = (params) => post('/v1/performance/table/template/list', { request: params });
/**
 * 考核表模板保存
 * @param params
 * @returns {*}
 */
export const performanceTableTemplateSave = (params) => post('/v1/performance/table/template/save', { request: params });
/**
 * 绩效考核员工导出
 * @param params
 * @returns {*}
 */
export const performanceAssessStaffExport = (params) => get('/v1/performance/assess/staff/export', { request: params });
/**
 * 绩效等级导出
 * @param params
 * @returns {*}
 */
export const performanceLevelExport = (params) => get('/v1/performance/level/export', { request: params });
/**
 * 绩效考核确认导出
 * @param params
 * @returns {*}
 */
export const performanceAssessAffirmExport = (params) => get('/v1/performance/assess/affirm/export', { request: params });
//绩效考核员工绩效列表
export const performanceStaffList = (params) => post('/v1/performance/staff/list', { request: params });
//绩效考核员工绩效填写
export const assessStaffWrite = (params) => post('/v1/performance/assess/staff/write', { request: params });
//绩效考核员工绩效自评
export const assessStaffEvaluate = (params) => post('/v1/performance/assess/staff/evaluate', { request: params });
//我的绩效辅导列表
export const performanceTutorship = (params) => post('/v1/performance/assess/staff/tutorship/list', { request: params });
//绩效考核绩效计划列表
export const performancePlanList = (params) => post('/v1/performance/plan/list', { request: params });
//绩效考核员工绩效审批
export const performanceApproval = (params) => post('/v1/performance/assess/approval', { request: params });
//绩效考核员工绩效评定
export const performanceAssessEvaluate = (params) => post('/v1/performance/assess/evaluate', { request: params });
//我的绩效辅导列表
export const performanceTutorshipList = (params) => post('/v1/performance/assess/tutorship/list', { request: params });
//绩效辅导提交
export const tutorshipSubmit = (params) => post('/v1/performance/assess/tutorship/submit', { request: params });
//员工绩效辅导记录
export const tutorshipRecord = (params) => post('/v1/performance/assess/tutorship/record', { request: params });
//确认人（后台管理系统管理员）下拉
export const AdminList = (params) => post('/v1/user/admin/staff/list', { request: params });
//查看组织绩效列表
export const OrgList = (params) => post('/v1/performance/org/list', { request: params })